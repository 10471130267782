/* Layout */
body {
  margin: 0;
}

main {
  display: block;
}

/* Text */
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
}

dl,
dd,
ol,
ul,
p,
address,
blockquote,
figure,
figcaption,
pre {
  margin: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

pre {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 0.75em;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 0.75em;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content */
img {
  border: 0;
}

/* Forms */
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  padding: 0;
  bottom: 0;
  color: inherit;
  font-size: inherit;
  -webkit-appearance: none;
}

input,
textarea {
  font-family: inherit;
}

textarea {
  resize: vertical;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-appearance: textfield;
}

/* Tables */
table {
  border-collapse: collapse;
}

th,
td {
  padding: 0;
}

th {
  vertical-align: bottom;
}

td {
  vertical-align: top;
}

/* $color--one: #fc731b;
$color--one-light: #FF9329;
$color--one-dark: #ec5312;
$color--one-lighter: #ffdfa8;
$color--two: rgb(250, 247, 241);
$color--two-light: #fff;
$color--three: rgb(89, 108, 113); */
@font-face {
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Mercury Display A", "Mercury Display B";
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Mercury Display A", "Mercury Display B";
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Mercury Display A", "Mercury Display B";
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Mercury Display A", "Mercury Display B";
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "modern pictograms";
  font-weight: 100;
  src: url(../fonts/modernpictogramspro_234.woff);
}
/* Basic Setup */
@lost flexbox flex;
@lost gutter 20px;
.columnGrid {
  display: none;
  position: fixed;
  width: 85%;
  left: 7.5%;
  height: 100vh;
  z-index: 5000;
  pointer-events: none;
  opacity: 0.5;
  lost-utility: clearfix;
}
@media (min-width: 550px) {
  .columnGrid {
    width: 100%;
    left: 0;
  }
}
.columnGrid .column {
  lost-column: 1/6 no-flex;
  lost-utility: clearfix;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid cyan;
}
@media (min-width: 550px) {
  .columnGrid .column {
    lost-column: 1/8 no-flex;
  }
}
@media (min-width: 1000px) {
  .columnGrid .column {
    lost-column: 1/11 no-flex;
  }
}
@media (min-width: 1200px) {
  .columnGrid .column {
    lost-column: 1/14 no-flex;
  }
}

html {
  /* lost-utility: edit; */
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.666;
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
  background: #faf7f1;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
  width: 100%;
  lost-center: 100%;
  align-items: baseline;
  overflow-x: hidden;
  color: #596c71;
  /* overflow-x: hidden; */
}

::selection {
  background: #596c71;
  color: #faf7f1;
}

main {
  margin-top: calc( var(--notification-height) + 6.664rem );
}

section {
  margin-bottom: 2.499rem;
}

article {
  margin-bottom: 2.499rem;
}

h1 {
  font-size: 2rem;
  font-weight: 400;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

h4 {
  font-weight: 600;
}

h5 {
  font-weight: 200;
}

a {
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
}

strong {
  font-weight: 600;
}

figure[data-type=quote] {
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-weight: 100;
  margin-bottom: 0;
  margin-left: 0;
  font-style: italic;
  position: relative;
  padding-left: 0;
  border-left: none;
}

figure[data-type=table] {
  font-size: 12px;
  overflow-x: scroll;
}
@media (min-width: 550px) {
  figure[data-type=table] {
    overflow-x: scroll;
  }
}
@media (min-width: 1000px) {
  figure[data-type=table] {
    overflow-x: visible;
  }
}
@media (min-width: 1200px) {
  figure[data-type=table] {
    overflow-x: visible;
  }
}

table {
  width: 100%;
  table-layout: fixed;
  margin-bottom: 1.666rem;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  overflow-x: scroll;
}
@media (min-width: 750px) {
  table {
    width: 100%;
  }
}
@media (min-width: 550px) {
  table {
    overflow-x: scroll;
  }
}
@media (min-width: 1000px) {
  table {
    overflow-x: visible;
  }
}
@media (min-width: 1200px) {
  table {
    overflow-x: visible;
  }
}

tr:last-child td {
  border-bottom: none;
}

th {
  border-right: 1px solid #f5dbc9;
  border-bottom: 1px solid #f5dbc9;
  font-weight: 600;
  text-align: left;
  padding-right: 0.833rem;
  padding-left: 0.833rem;
  padding-bottom: 0.833rem;
  vertical-align: bottom;
  width: 100px;
}
th:first-child {
  padding-left: 0;
}
th:last-child {
  border-right: none;
}

td {
  padding-right: 0.833rem;
  padding-left: 0.833rem;
  padding-top: 1.666rem;
  padding-bottom: 1.666rem;
  border-right: 1px solid rgb(215, 228, 231);
  border-bottom: 1px solid rgb(215, 228, 231);
  width: 100px;
}
td:first-child {
  padding-left: 0;
}
td:last-child {
  border-right: none;
}

ul li {
  list-style: none;
}

ol li {
  list-style: decimal;
}
ol li ul li {
  list-style: disc;
}

input[type=search] {
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.header {
  transition: all 0.2s ease;
  z-index: 7000;
  width: 100vw;
  height: 4.165rem;
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  color: #faf7f1;
  background: #f9553f;
}
.header-content {
  background: #f9553f;
  position: relative;
  lost-center: 85%;
  z-index: 8000;
  align-items: center;
  height: 100%;
}
@media (min-width: 550px) {
  .header-content {
    lost-center: 100%;
  }
}
.header-title {
  display: inline-block;
  lost-column: 5/6 flex;
  lost-offset: 0;
  z-index: 500;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.666;
  box-shadow: none;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
@media (min-width: 550px) {
  .header-title {
    lost-column: 5/8 flex;
    lost-offset: 1/8;
    box-shadow: none;
  }
}
@media (min-width: 1000px) {
  .header-title {
    lost-column: 3/11 flex;
    lost-offset: 1/11;
    box-shadow: 48px -2px 9px -1px #f9553f;
  }
}
@media (min-width: 1200px) {
  .header-title {
    lost-column: 3/14 flex;
    lost-offset: 1/14;
    box-shadow: 48px -2px 9px -1px #f9553f;
  }
}
.header-searchBar-static {
  lost-column: 1/6 2 flex;
  display: flex;
  cursor: pointer;
  z-index: 200;
  margin-top: 0;
}
@media (min-width: 550px) {
  .header-searchBar-static {
    lost-column: 1/8 flex;
    display: flex;
  }
}
@media (min-width: 1000px) {
  .header-searchBar-static {
    lost-column: 6/11 flex;
    display: flex;
  }
}
@media (min-width: 1200px) {
  .header-searchBar-static {
    lost-column: 9/14 flex;
    display: flex;
  }
}
.header-searchBar-static[data-icon]:after {
  font-family: "modern pictograms";
  content: attr(data-icon);
  speak: none;
  font-size: 3rem;
  cursor: default;
  position: absolute;
  right: 0;
  top: -3px;
}
@media (min-width: 550px) {
  .header-searchBar-static[data-icon]:after {
    position: static;
    right: inherit;
    top: inherit;
  }
}
.header-searchForm {
  display: none;
  width: 100%;
  padding: 0 1.666rem 0 0;
  color: #faf7f1;
  background-color: transparent;
  text-align: right;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.666;
  /* margin-top: -2px; */
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
  /* @include type--allCaps; */
}
@media (min-width: 550px) {
  .header-searchForm {
    display: none;
  }
}
@media (min-width: 1000px) {
  .header-searchForm {
    display: block;
  }
}
@media (min-width: 1200px) {
  .header-searchForm {
    display: block;
  }
}
.header-searchForm:-moz-placeholder {
  color: #faf7f1;
  opacity: 1;
}
.header-searchForm::-webkit-input-placeholder {
  color: #faf7f1;
  opacity: 1;
}
.header-searchForm::-moz-placeholder {
  color: #faf7f1;
  opacity: 1;
}
.header-searchForm:-ms-input-placeholder {
  color: #faf7f1;
  opacity: 1;
}
.header-searchForm:focus {
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
}
.header-searchForm::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.header-searchBar-dropdown {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 4.165rem;
  background: #f9553f;
  lost-column: 6/6 flex;
  transition: all 0.2s ease;
  z-index: 7000;
}
@media (min-width: 550px) {
  .header-searchBar-dropdown {
    display: block;
  }
}
@media (min-width: 1000px) {
  .header-searchBar-dropdown {
    display: none;
  }
}
@media (min-width: 1200px) {
  .header-searchBar-dropdown {
    display: none;
  }
}
.header-searchForm-dropdown {
  display: block;
  margin-left: 7.5%;
  /* lost-offset: -1/6; */
  width: 100%;
  color: #faf7f1;
  background-color: transparent;
  text-align: left;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.666;
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
  /* @include type--allCaps; */
}
@media (min-width: 550px) {
  .header-searchForm-dropdown {
    display: block;
    lost-offset: 1/8;
  }
}
@media (min-width: 1000px) {
  .header-searchForm-dropdown {
    display: none;
  }
}
@media (min-width: 1200px) {
  .header-searchForm-dropdown {
    display: none;
  }
}
.header-searchForm-dropdown:-moz-placeholder {
  color: #faf7f1;
  opacity: 1;
}
.header-searchForm-dropdown::-webkit-input-placeholder {
  color: #faf7f1;
  opacity: 1;
}
.header-searchForm-dropdown::-moz-placeholder {
  color: #faf7f1;
  opacity: 1;
}
.header-searchForm-dropdown:-ms-input-placeholder {
  color: #faf7f1;
  opacity: 1;
}
.header-searchForm-dropdown:focus {
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
}
.header-searchForm-dropdown::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.nav-up {
  top: -4.165rem;
}

.isDropped {
  top: cal(var(--notification-height)4.165rem);
}

body.notification--is-opened .header {
  top: var(--notification-height);
}

body.notification--is-opened.nav-up .header {
  top: calc( (var(--notification-height) * -1) + -4.165rem );
}

.index {
  font-size: 1.25rem;
  lost-center: 85%;
  width: 100%;
}
@media (min-width: 550px) {
  .index {
    lost-center: 100%;
  }
}
.index-title {
  lost-column: 1;
  font-size: 3rem;
  font-family: "Mercury Display A", "Mercury Display B";
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 2.499rem;
  color: #f9553f;
  lost-column: 5/6;
  lost-offset: 0;
}
@media (min-width: 550px) {
  .index-title {
    font-size: 4rem;
    lost-column: 6/8;
    lost-offset: 1/8;
  }
}
@media (min-width: 1000px) {
  .index-title {
    font-size: 6rem;
    lost-column: 8/11;
    lost-offset: 1/11;
  }
}
@media (min-width: 1200px) {
  .index-title {
    font-size: 6rem;
    lost-column: 12/14;
    lost-offset: 1/14;
  }
}
.index-list {
  lost-column: 5/6 no-flex;
  lost-offset: 0;
}
@media (min-width: 550px) {
  .index-list {
    lost-column: 6/8 no-flex;
    lost-offset: 1/8;
  }
}
@media (min-width: 1000px) {
  .index-list {
    lost-column: 6/11 no-flex;
    lost-offset: 1/11;
  }
}
@media (min-width: 1200px) {
  .index-list {
    lost-column: 8/14 no-flex;
    lost-offset: 1/14;
  }
}
.index-list-alphabet {
  lost-column: 1;
  margin-bottom: 2.499rem;
  font-family: "Mercury Display A", "Mercury Display B";
  font-size: 2rem;
  font-weight: 400;
  color: #f9553f;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
.index-list-office {
  margin-bottom: 2.499rem;
  lost-utility: clearfix;
  border-bottom: 1px solid #596c71;
}
.index-list-office:last-child {
  border-bottom: 1px solid #596c71;
}
@media (min-width: 550px) {
  .index-list-office:last-child {
    border-bottom: 1px solid #596c71;
  }
}
@media (min-width: 1000px) {
  .index-list-office:last-child {
    border-bottom: none;
  }
}
@media (min-width: 1200px) {
  .index-list-office:last-child {
    border-bottom: none;
  }
}
.index-list-item {
  lost-column: 1 no-flex;
  color: #596c71;
  margin-bottom: 1.666rem;
}
@media (min-width: 550px) {
  .index-list-item {
    lost-column: 1/2 no-flex;
  }
}
@media (min-width: 1000px) {
  .index-list-item {
    lost-column: 1/2 no-flex;
  }
}
@media (min-width: 1200px) {
  .index-list-item {
    lost-column: 1/2 no-flex;
  }
}
.index-related {
  lost-column: 5/6 no-flex;
  lost-offset: 0;
}
@media (min-width: 550px) {
  .index-related {
    lost-column: 6/8 no-flex;
    lost-offset: 1/8;
  }
}
@media (min-width: 1000px) {
  .index-related {
    lost-column: 2/11 no-flex;
    lost-offset: 1/11;
  }
}
@media (min-width: 1200px) {
  .index-related {
    lost-column: 3/14 no-flex;
    lost-offset: 1/14;
  }
}
.index-related-title {
  margin-bottom: 1.666rem;
  font-family: "Mercury Display A", "Mercury Display B";
  font-size: 2rem;
  font-weight: 400;
  color: #596c71;
}
.index-related-item {
  margin-bottom: 0.833rem;
  padding-bottom: 0.833rem;
  border-bottom: none;
  color: #f9553f;
}
@media (min-width: 550px) {
  .index-related-item {
    border-bottom: none;
  }
}
@media (min-width: 1000px) {
  .index-related-item {
    border-bottom: 1px solid #f9553f;
  }
}
@media (min-width: 1200px) {
  .index-related-item {
    border-bottom: 1px solid #f9553f;
  }
}
.index-related-item:last-child {
  border-bottom: none;
}

.alpha-index {
  lost-column: 5/6 no-flex;
  lost-offset: 0;
}
@media (min-width: 550px) {
  .alpha-index {
    lost-column: 6/8 no-flex;
    lost-offset: 1/8;
  }
}
@media (min-width: 1000px) {
  .alpha-index {
    lost-column: 6/11 no-flex;
    lost-offset: 1/11;
  }
}
@media (min-width: 1200px) {
  .alpha-index {
    lost-column: 8/14 no-flex;
    lost-offset: 1/14;
  }
}

.alpha-index__list {
  lost-utility: clearfix;
  padding-bottom: 2.499rem;
  border-bottom: 1px solid #596c71;
  text-align: left;
}

.alpha-index__character {
  display: inline-block;
  padding-right: 1.666rem;
  text-transform: uppercase;
}

.individual {
  lost-center: 85%;
  width: 85%;
  lost-utility: clearfix;
}
@media (min-width: 550px) {
  .individual {
    lost-center: 100%;
    width: 100%;
  }
}
@media (min-width: 1000px) {
  .individual {
    lost-center: 100%;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .individual {
    lost-center: 100%;
    width: 100%;
  }
}
.individual-title {
  font-family: "Mercury Display A", "Mercury Display B";
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1.666rem;
  color: #f9553f;
  lost-column: 5/6 no-flex;
  lost-offset: 0;
}
@media (min-width: 550px) {
  .individual-title {
    font-size: 4rem;
    margin-bottom: 1.666rem;
    lost-column: 5/8 no-flex;
    lost-offset: 1/8;
  }
}
@media (min-width: 1000px) {
  .individual-title {
    font-size: 6rem;
    margin-bottom: 2.499rem;
    lost-column: 9/11 no-flex;
    lost-offset: 1/11;
  }
}
@media (min-width: 1200px) {
  .individual-title {
    font-size: 6rem;
    margin-bottom: 2.499rem;
    lost-column: 13/14 no-flex;
    lost-offset: 1/14;
  }
}
.individual-mobileSidebar {
  display: block;
  z-index: 900;
  lost-column: 6/8 1 no-flex;
  position: fixed;
  top: 0;
  left: 85%;
}
@media (min-width: 550px) {
  .individual-mobileSidebar {
    display: block;
    lost-column: 7/10 1 no-flex;
    position: fixed;
    top: 0;
    left: calc(85% - 20px);
  }
}
@media (min-width: 1000px) {
  .individual-mobileSidebar {
    display: none;
    top: inherit;
  }
}
@media (min-width: 1200px) {
  .individual-mobileSidebar {
    display: none;
    top: inherit;
  }
}
.individual-desktopSidebar {
  display: none;
  z-index: 5000;
}
@media (min-width: 550px) {
  .individual-desktopSidebar {
    display: none;
  }
}
@media (min-width: 1000px) {
  .individual-desktopSidebar {
    lost-column: 3/11 2 no-flex;
    lost-offset: 1/11;
    display: block;
  }
}
@media (min-width: 1200px) {
  .individual-desktopSidebar {
    lost-column: 4/14 0 no-flex;
    lost-offset: 2/14;
    display: block;
  }
}
.individual-flipContainer {
  perspective: 1000;
  height: 0;
  width: inherit;
}
.individual-flipContainer-mobile {
  /* position: fixed; */
}
.individual-flipper-desktop, .individual-flipper-mobile {
  transition: 0.3s ease;
  transform-style: preserve-3d;
  position: relative;
}
.individual-flipper-mobile {
  transition: 0.2s ease;
  transform-origin: left center;
  /* height: 120vh;
  background-color: $color--two; */
}
.individual-flipper-front {
  z-index: 2;
  transform: rotateY(0deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  fill: #596c71;
}
.individual-flipper-back {
  transform: rotateY(180deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  fill: #f9553f;
  color: #f9553f;
  height: 120vh;
  background-color: #faf7f1;
  padding-left: 0.833rem;
}
.individual-flipper-toggle {
  transform: rotateY(180deg);
}
.individual-flipper-toggle-mobile {
  margin-left: -11px;
}
.individual-hamburger-desktop-toggled, .individual-hamburger-desktop-notToggled, .individual-hamburger-mobile-toggled, .individual-hamburger-mobile-notToggled {
  font-family: "modern pictograms";
  font-size: 4rem;
  margin-top: -1.666rem;
  /* text-indent: -0.38rem; */
  transition: 0.5s color ease;
  z-index: 2000;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  display: block;
}
.individual-hamburger-desktop-toggled svg, .individual-hamburger-desktop-notToggled svg, .individual-hamburger-mobile-toggled svg, .individual-hamburger-mobile-notToggled svg {
  width: 2.499rem;
}
.individual-hamburger-desktop-toggled, .individual-hamburger-mobile-toggled {
  text-align: right;
  float: right;
}
.individual-hamburger-mobile-toggled, .individual-hamburger-mobile-notToggled {
  margin-top: 5.831rem;
}
@media (min-width: 550px) {
  .individual-hamburger-mobile-toggled, .individual-hamburger-mobile-notToggled {
    margin-top: 6.664rem;
  }
}
.individual-hamburger-mobile-navUp {
  margin-top: 0.833rem;
}
.individual-hamburger-desktop-navUp {
  margin-top: -6.664rem;
}
.individual-basicInfo-desktop, .individual-basicInfo-mobile {
  font-size: 1rem;
  font-weight: 600;
  font-variant: oldstyle-nums;
  border-bottom: 1px solid #596c71;
  border-top: 1px solid #596c71;
  lost-utility: clearfix;
}
@media (min-width: 550px) {
  .individual-basicInfo-desktop, .individual-basicInfo-mobile {
    border-bottom: 1px solid #596c71;
    border-top: 1px solid #596c71;
  }
}
@media (min-width: 1000px) {
  .individual-basicInfo-desktop, .individual-basicInfo-mobile {
    border-bottom: none;
    border-top: none;
  }
}
.individual-basicInfo-desktop a, .individual-basicInfo-mobile a {
  color: #f9553f;
}
.individual-basicInfo-desktop dl, .individual-basicInfo-mobile dl {
  border-bottom: none;
}
@media (min-width: 550px) {
  .individual-basicInfo-desktop dl, .individual-basicInfo-mobile dl {
    lost-column: 1/2 2 no-flex;
    border-bottom: none;
  }
}
@media (min-width: 1000px) {
  .individual-basicInfo-desktop dl, .individual-basicInfo-mobile dl {
    lost-column: 1 no-flex;
  }
}
@media (min-width: 1200px) {
  .individual-basicInfo-desktop dl, .individual-basicInfo-mobile dl {
    lost-column: 1 no-flex;
  }
}
.individual-basicInfo-desktop dt, .individual-basicInfo-mobile dt {
  font-weight: 400;
}
.individual-basicInfo-desktop .hours, .individual-basicInfo-mobile .hours {
  /* text-indent: $layout--space-small; */
  /* font-weight: $type--weight-book; */
}
.individual-basicInfo-desktop:not(:last-child) {
  margin-bottom: 1.666rem;
}
.individual-basicInfo-desktop:not(:last-child) dl {
  padding-bottom: 0.833rem;
  margin-bottom: 0;
}
.individual-basicInfo-desktop:not(:last-child) dl:last-child {
  padding-bottom: 1.666rem;
  border-bottom: 1px solid #596c71;
}
.individual-basicInfo-desktop:last-child dl {
  padding-bottom: 0.833rem;
}
.individual-basicInfo-desktop:last-child dl:last-child {
  border-bottom: none;
}
.individual-basicInfo-mobile {
  display: block;
  padding-top: 2.499rem;
  padding-bottom: 1.666rem;
}
.individual-basicInfo-mobile dl {
  padding-bottom: 0.833rem;
  margin-bottom: 0.833rem;
}
.individual-basicInfo-mobile dl:last-child {
  border-bottom: none;
}
@media (min-width: 550px) {
  .individual-basicInfo-mobile {
    display: block;
    padding-top: 2.499rem;
    padding-bottom: 1.666rem;
  }
}
@media (min-width: 1000px) {
  .individual-basicInfo-mobile {
    display: none;
  }
}
@media (min-width: 1200px) {
  .individual-basicInfo-mobile {
    display: none;
  }
}
.individual-subMenu-desktop, .individual-subMenu-mobile {
  color: #f9553f;
  font-weight: 100;
  font-size: 1rem;
  width: 100%;
  transition: 0.5s margin ease;
}
.individual-subMenu-mobile {
  background-color: #faf7f1;
  padding: 20px 0;
}
.individual-subMenu-links a:last-child li {
  border-bottom: none;
}
.individual-subMenu-item {
  margin-bottom: 0.833rem;
  padding-bottom: 0.833rem;
  border-bottom: 1px solid #596c71;
}
@media (min-width: 550px) {
  .individual-subMenu-item {
    margin-bottom: calc(0.833rem / 2);
    padding-bottom: calc(0.833rem / 2);
  }
}
@media (min-width: 1000px) {
  .individual-subMenu-item {
    border-bottom: none;
  }
}
.individual-subMenu #topJump span {
  font-family: "modern pictograms";
  font-size: 2rem;
  top: 3px;
  position: relative;
}
.individual-content {
  lost-column: 5/6 no-flex;
  lost-offset: 0;
  margin-top: 0.833rem;
  font-size: 1.25rem;
  word-wrap: break-word;
  hyphens: none;
  /* overflow-x: hidden; */
}
@media (min-width: 550px) {
  .individual-content {
    lost-column: 5/8 no-flex;
    lost-offset: 1/8;
    margin-top: 1.666rem;
    /* overflow-x: hidden; */
  }
}
@media (min-width: 1000px) {
  .individual-content {
    lost-column: 5/11 no-flex;
    lost-offset: 1/11;
    margin-top: 0;
    overflow-x: visible;
  }
}
@media (min-width: 1200px) {
  .individual-content {
    lost-column: 6/14 0 no-flex;
    lost-offset: 1/14;
    margin-top: 0;
    overflow-x: visible;
  }
}
.individual-content ul li {
  hyphens: none;
  list-style: disc inside;
  text-indent: -1em;
  padding-left: 1em;
}
@media (min-width: 1000px) {
  .individual-content ul li {
    text-indent: 0;
    padding-left: 0;
    list-style: disc outside;
  }
}
.individual-content ul li ul {
  padding-left: 2em;
}
.individual-content ul li ul li {
  list-style: circle inside;
}
@media (min-width: 1000px) {
  .individual-content ul li ul li {
    text-indent: 0;
    padding-left: 0;
    list-style: circle outside;
  }
}
.individual-content ol li {
  hyphens: none;
  list-style: decimal inside;
  text-indent: -1em;
  padding-left: 1em;
}
@media (min-width: 1000px) {
  .individual-content ol li {
    text-indent: 0;
    padding-left: 0;
    list-style: decimal outside;
  }
}
.individual-content ol li ul li {
  hyphens: none;
  list-style: disc inside;
  text-indent: -1em;
  padding-left: 1em;
}
@media (min-width: 1000px) {
  .individual-content ol li ul li {
    text-indent: 0;
    padding-left: 0;
    list-style: disc outside;
  }
}
.individual-content ol li ol li {
  hyphens: none;
  list-style: disc inside;
  text-indent: -1em;
  padding-left: 1em;
}
@media (min-width: 1000px) {
  .individual-content ol li ol li {
    text-indent: 0;
    padding-left: 0;
    list-style: disc outside;
  }
}
.individual-content img {
  width: 100%;
  height: auto;
}
.individual-content figure[data-type=quote] {
  hyphens: none;
  lost-column: 1 no-flex;
  lost-offset: 0;
  margin-bottom: 1.666rem;
}
@media (min-width: 550px) {
  .individual-content figure[data-type=quote] {
    lost-column: 4/5 no-flex;
    lost-offset: 1/5;
  }
}
@media (min-width: 1000px) {
  .individual-content figure[data-type=quote] {
    lost-column: 4/5 no-flex;
    lost-offset: 1/5;
  }
}
@media (min-width: 1200px) {
  .individual-content figure[data-type=quote] {
    lost-column: 5/6 0 no-flex;
    lost-offset: 1/6;
  }
}
.individual-content a {
  word-break: break-all;
  word-break: break-word;
  hyphens: none;
}
.individual-content-description, .individual-content-temporary-contact-info {
  vertical-align: top;
}
.individual-content-description p, .individual-content-temporary-contact-info p {
  margin-bottom: 1.666rem;
  hyphens: none;
}
.individual-content-description ul, .individual-content-temporary-contact-info ul {
  hyphens: none;
  margin-bottom: 1.666rem;
  list-style-type: disc;
  list-style-position: inside;
  list-style-image: none;
}
.individual-content-description ul ul, .individual-content-temporary-contact-info ul ul {
  margin-left: 1.666rem;
  margin-bottom: 0;
}
.individual-content-description a, .individual-content-temporary-contact-info a {
  hyphens: none;
  border-bottom: 1px solid #f9553f;
}
.individual-content-temporary-contact-info .individual-content-subPageTitle {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}
.individual-content-contacts {
  hyphens: none;
}
.individual-content-contacts a {
  border-bottom: 1px solid #f9553f;
  hyphens: none;
}
.individual-content-subPages {
  vertical-align: top;
}
.individual-content-subPages p {
  hyphens: none;
  margin-bottom: 1.666rem;
}
.individual-content-subPages ul {
  hyphens: none;
  margin-bottom: 1.666rem;
  list-style-type: disc;
  list-style-position: inside;
  list-style-image: none;
}
.individual-content-subPages a {
  border-bottom: 1px solid #f9553f;
}
.individual-content-subPageTitle {
  font-family: "Mercury Display A", "Mercury Display B";
  font-size: 2rem;
  margin-top: 2.499rem;
  margin-bottom: 0.833rem;
  font-weight: 400;
  color: #f9553f;
  padding-top: 1.666rem;
  border-top: 1px solid #596c71;
}
.individual-content-subPageTitle .anchor-jump {
  display: block;
  position: relative;
  top: -4.165rem;
  visibility: hidden;
}
.individual-content-related {
  border-top: 1px solid #596c71;
}
.individual-content-relatedLinks, .individual-content-documents {
  padding-top: 2.499rem;
  margin-bottom: 0;
}
.individual-content-relatedLinks ul, .individual-content-documents ul {
  list-style: none;
}
.individual-content-relatedLinks ul li, .individual-content-documents ul li {
  hyphens: none;
  margin-bottom: 0.833rem;
  color: #f9553f;
  list-style: none;
}
.individual ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.individual ::-webkit-scrollbar-track {
  background-color: #faf7f1;
}
.individual ::-webkit-scrollbar-thumb {
  background-color: rgb(215, 228, 231);
}
.individual ::-webkit-scrollbar-button {
  background-color: #f5dbc9;
}
.individual ::-webkit-scrollbar-corner {
  background-color: #f5dbc9;
}

.individual-content-temporary-contact-info + .individual-content-description {
  border-top: 1px solid #596c71;
  padding-top: 2.499rem;
}

.individual-desktopSidebar-container {
  position: relative;
  height: 100%;
}

.individual-desktopSidebar-scroll {
  position: relative;
  overflow-y: scroll;
}

.searchResults {
  font-family: "Gotham SSm A", "Gotham SSm B";
  width: 100%;
}
.searchResults .wh-search-results {
  lost-utility: clearfix;
  lost-center: 85%;
}
@media (min-width: 550px) {
  .searchResults .wh-search-results {
    lost-center: 100%;
  }
}
@media (min-width: 1000px) {
  .searchResults .wh-search-results {
    lost-center: 100%;
  }
}
@media (min-width: 1200px) {
  .searchResults .wh-search-results {
    lost-center: 100%;
  }
}
.searchResults .wh-search-results-total {
  lost-column: 5/6 no-flex;
  lost-offset: 0;
  color: #f9553f;
  font-family: "Mercury Display A", "Mercury Display B";
  font-size: 4rem;
  font-weight: 400;
  margin-bottom: 2.499rem;
  /* border-bottom: solid 1px $color--three; */
}
@media (min-width: 550px) {
  .searchResults .wh-search-results-total {
    lost-column: 6/8 no-flex;
    lost-offset: -1/8;
  }
}
@media (min-width: 1000px) {
  .searchResults .wh-search-results-total {
    lost-column: 9/11 no-flex;
    lost-offset: -1/11;
  }
}
@media (min-width: 1200px) {
  .searchResults .wh-search-results-total {
    lost-column: 12/14 no-flex;
    lost-offset: -1/14;
  }
}
.searchResults dt {
  width: 100%;
}
.searchResults dd {
  font-family: "Gotham SSm A", "Gotham SSm B";
  lost-column: 5/6 no-flex;
  lost-offset: 0;
  margin-bottom: 1.666rem;
  padding-bottom: 1.666rem;
  border-bottom: 1px solid #596c71;
}
@media (min-width: 550px) {
  .searchResults dd {
    lost-column: 6/8 no-flex;
    lost-offset: -1/8;
  }
}
@media (min-width: 1000px) {
  .searchResults dd {
    lost-column: 9/11 no-flex;
    lost-offset: -1/11;
  }
}
@media (min-width: 1200px) {
  .searchResults dd {
    lost-column: 12/14 no-flex;
    lost-offset: -1/14;
  }
}
.searchResults h2 {
  font-size: 1.5rem;
  lost-column: 1 no-flex;
}
@media (min-width: 550px) {
  .searchResults h2 {
    lost-column: 1 no-flex;
  }
}
@media (min-width: 1000px) {
  .searchResults h2 {
    lost-column: 3/9 no-flex;
  }
}
@media (min-width: 1200px) {
  .searchResults h2 {
    lost-column: 4/12 no-flex;
  }
}
.searchResults .wh-search-url {
  font-size: 1.25rem;
  font-weight: 100;
  color: #596c71;
}
.searchResults .wh-search-term-in-page {
  font-weight: 100;
}
.searchResults .wh-search-term {
  font-weight: 600;
  color: #f9553f;
}
.searchResults .wh-search-body {
  font-size: 1.25rem;
  font-weight: 400;
  color: #596c71;
  lost-column: 1 no-flex;
}
@media (min-width: 550px) {
  .searchResults .wh-search-body {
    lost-column: 1 no-flex;
  }
}
@media (min-width: 1000px) {
  .searchResults .wh-search-body {
    lost-column: 6/9 no-flex;
  }
}
@media (min-width: 1200px) {
  .searchResults .wh-search-body {
    lost-column: 8/12 no-flex;
  }
}
.searchResults .wh-search-paginate {
  lost-utility: clearfix;
  lost-center: 85%;
}
@media (min-width: 550px) {
  .searchResults .wh-search-paginate {
    lost-center: 100%;
    lost-column: 6/8 no-flex;
    lost-offset: -1/8;
  }
}
@media (min-width: 1000px) {
  .searchResults .wh-search-paginate {
    lost-center: 100%;
    lost-column: 9/11 no-flex;
    lost-offset: -1/11;
  }
}
@media (min-width: 1200px) {
  .searchResults .wh-search-paginate {
    lost-center: 100%;
    lost-column: 12/14 no-flex;
    lost-offset: -1/14;
  }
}
.searchResults .wh-search-paginate .active {
  color: #f9553f;
}
.searchResults .wh-search-paginate li {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 400;
  margin-right: 1.666rem;
  margin-bottom: 2.499rem;
  margin-top: 2.499rem;
}
.searchResults .wh-search-error {
  lost-column: 5/6 no-flex;
  /* lost-offset: 0; */
  margin-left: 7.5%;
  color: #f9553f;
  font-family: "Mercury Display A", "Mercury Display B";
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 2.499rem;
}
@media (min-width: 550px) {
  .searchResults .wh-search-error {
    lost-column: 6/8 no-flex;
    lost-offset: -1/8;
    font-size: 3rem;
  }
}
@media (min-width: 1000px) {
  .searchResults .wh-search-error {
    lost-column: 8/11 no-flex;
    lost-offset: -1/11;
    font-size: 4rem;
  }
}
@media (min-width: 1200px) {
  .searchResults .wh-search-error {
    lost-column: 12/14 no-flex;
    lost-offset: -1/14;
    font-size: 4rem;
  }
}

.search-results {
  z-index: 6000;
}

.search-results__list {
  background-color: #faf7f1;
  padding: 6rem 7.5% 3rem 7.5%;
  overflow-y: auto;
  box-sizing: border-box;
}
@media (min-width: 1000px) {
  .search-results__list {
    padding: 3rem 7.5%;
  }
}

.search-results__box {
  height: 100%;
}

.search-results__result {
  font-family: "Gotham SSm A", "Gotham SSm B";
}

.search-results__item {
  font-family: "Gotham SSm A", "Gotham SSm B";
  lost-column: 5/6 no-flex;
  lost-offset: 0;
  margin-bottom: 1.666rem;
  padding-bottom: 1.666rem;
  border-bottom: 1px solid #596c71;
}
.search-results__item:hover .search-results__title {
  color: #f9553f;
}

.search-results__title {
  font-size: 1.5rem;
  color: #596c71;
  transition: color 0.2s ease;
}

.search-results__body {
  color: #596c71;
}

.search-results__query {
  color: #f9553f;
}

.page.in-search {
  overflow-y: hidden;
  height: 100vh;
  flex-direction: row;
}

.campusMap {
  lost-center: 85%;
  width: 85%;
  lost-utility: clearfix;
}
@media (min-width: 550px) {
  .campusMap {
    lost-center: 100%;
    width: 100%;
  }
}
@media (min-width: 1000px) {
  .campusMap {
    lost-center: 100%;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .campusMap {
    lost-center: 100%;
    width: 100%;
  }
}
.campusMap-title {
  font-family: "Mercury Display A", "Mercury Display B";
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1.666rem;
  color: #f9553f;
  lost-column: 5/6 no-flex;
  lost-offset: 0;
}
@media (min-width: 550px) {
  .campusMap-title {
    font-size: 4rem;
    margin-bottom: 1.666rem;
    lost-column: 5/8 no-flex;
    lost-offset: 1/8;
  }
}
@media (min-width: 1000px) {
  .campusMap-title {
    font-size: 6rem;
    margin-bottom: 2.499rem;
    lost-column: 9/11 no-flex;
    lost-offset: 1/11;
  }
}
@media (min-width: 1200px) {
  .campusMap-title {
    font-size: 6rem;
    margin-bottom: 2.499rem;
    lost-column: 13/14 no-flex;
    lost-offset: 1/14;
  }
}
.campusMap-embed {
  lost-column: 5/6 no-flex;
  lost-offset: 0;
  margin-top: 0.833rem;
  font-size: 1.25rem;
  height: 60vh;
  /* overflow-x: hidden; */
}
@media (min-width: 550px) {
  .campusMap-embed {
    lost-column: 6/8 no-flex;
    lost-offset: 1/8;
    margin-top: 1.666rem;
    /* overflow-x: hidden; */
  }
}
@media (min-width: 1000px) {
  .campusMap-embed {
    lost-column: 9/11 no-flex;
    lost-offset: 1/11;
    margin-top: 0;
    overflow-x: visible;
  }
}
@media (min-width: 1200px) {
  .campusMap-embed {
    lost-column: 12/14 0 no-flex;
    lost-offset: 1/14;
    margin-top: 0;
    overflow-x: visible;
  }
}
.campusMap-embed iframe {
  width: 100%;
  height: 100%;
}
.campusMap-links {
  lost-column: 5/6 no-flex;
  lost-offset: 0;
  margin-top: 0.833rem;
  font-size: 1.25rem;
  /* overflow-x: hidden; */
}
@media (min-width: 550px) {
  .campusMap-links {
    lost-column: 5/8 no-flex;
    lost-offset: 1/8;
    margin-top: 0.833rem;
    /* overflow-x: hidden; */
  }
}
@media (min-width: 1000px) {
  .campusMap-links {
    lost-column: 5/11 no-flex;
    lost-offset: 1/11;
    margin-top: 0.833rem;
    overflow-x: visible;
  }
}
@media (min-width: 1200px) {
  .campusMap-links {
    lost-column: 6/14 0 no-flex;
    lost-offset: 1/14;
    margin-top: 0.833rem;
    overflow-x: visible;
  }
}
.campusMap-links a {
  color: #f9553f;
  display: block;
}

.four04-text {
  lost-column: 5/6 no-flex;
  /* lost-offset: 0; */
  margin-left: 7.5%;
  color: #f9553f;
  font-family: "Mercury Display A", "Mercury Display B";
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 2.499rem;
}
@media (min-width: 550px) {
  .four04-text {
    lost-column: 6/8 no-flex;
    lost-offset: 1/8;
    font-size: 3rem;
  }
}
@media (min-width: 1000px) {
  .four04-text {
    lost-column: 8/11 no-flex;
    lost-offset: 1/11;
    font-size: 4rem;
  }
}
@media (min-width: 1200px) {
  .four04-text {
    lost-column: 12/14 no-flex;
    lost-offset: 1/14;
    font-size: 4rem;
  }
}
.four04-text a {
  border-bottom: 1px solid #596c71;
}

body.popUp-open {
  overflow: hidden;
}

.popUp-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(250, 247, 241, 0.5);
}

.popUp-box {
  font-size: 1.25rem;
  margin: 2.499rem;
  max-width: 30em;
  max-height: 100vh;
  box-sizing: border-box;
  padding: 50px;
  background-color: #f9553f;
  color: #f9553f;
  position: relative;
}
.popUp-box .popUp-close {
  width: 100%;
  position: absolute;
  right: 1rem;
  top: 1rem;
  margin-bottom: 2.499rem;
}
.popUp-box .popUp-close svg {
  width: 30px;
  height: 30px;
  fill: #faf7f1;
  color: #faf7f1;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.popUp-content {
  height: 100%;
  max-height: calc(100vh - 50px - 50px);
  overflow-y: scroll;
  scrollbar-color: #f9553f #f9553f;
}

.popUp-content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.popUp-content::-webkit-scrollbar-thumb,
.popUp-content::-webkit-scrollbar-track {
  background: #f9553f;
}

.popUp-title {
  font-family: "Mercury Display A", "Mercury Display B";
  font-size: 3rem;
  line-height: 1.2;
  color: #faf7f1;
  margin-bottom: 0.833rem;
}
@media (min-width: 550px) {
  .popUp-title {
    font-size: 4rem;
  }
}

.popUp-text {
  font-size: 1.25rem;
  color: #faf7f1;
}
.popUp-text p {
  margin-bottom: 0.833rem;
}
.popUp-text a {
  border-bottom: 2px solid #faf7f1;
}

.popUp-hide {
  display: none;
}

/* map function */
/* responsive mixins */
/* type utilities */
.notification {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.notification {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

/* icon mixin */
.notification button::after {
  display: block;
  margin-right: auto;
  margin-left: auto;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
}

.notification {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 15px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.notification p {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.71429;
  max-width: 120em;
}
.notification span {
  display: block;
  position: relative;
  width: 92%;
  margin: 0 2rem 0 0;
  padding-bottom: 2px;
  text-align: center;
}
@media screen and (min-width: 513px) {
  .notification span {
    display: flex;
    flex-grow: 1;
    flex-basis: 80%;
    justify-content: center;
  }
}
.notification button {
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-top: 3px;
  margin: 0 10px;
  background-color: transparent;
  vertical-align: top;
  cursor: pointer;
  border: none;
}
.notification button::after {
  display: block;
  width: 1rem;
  height: 1rem;
  background-image: url("../images/notification--icon-close.svg");
  background-size: contain;
  position: absolute;
  top: 3px;
  right: 0px;
}
@media screen and (max-width: 768px) {
  .notification button {
    display: block;
    margin: 0 auto;
  }
}

.notification--is-closed {
  display: none;
}

.notification {
  z-index: 8000;
  transition: all 0.2s ease;
}